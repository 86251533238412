<template>
  <div class="content">
    <div class="search-attr">
      <div>
        <a-input
          v-model="searchParams.versionName"
          size="small"
          style="width: 150px; margin: 5px"
          placeholder="版别名称 或 ID"
          allowClear
          @keyup.enter="search"
        ></a-input>
        <!-- 选择大币种搜索 -->
        <a-select
          class="w-300 mb-5 ml-5"
          size="small"
          @search="bigCoinSearch"
          v-model="searchParams.coinSId"
          :filter-option="untils.filterOption"
          showSearch
          @change="selectBigCoin"
          placeholder="大币种"
          allowClear
        >
          <a-select-option
            v-for="item of searchBigCoins"
            :key="item.id"
            :value="item.sid"
          >{{ computedShowBigCoinSelectedList(item) }}</a-select-option>
        </a-select>
        <!-- 小币种 -->
        <a-select
          style="width: 150px; margin: 5px"
          size="small"
          showSearch
          v-model="searchParams.coinItemId"
          :filter-option="untils.filterOption"
          placeholder="小币种"
          allowClear
        >
          <a-select-option
            v-for="item of searchLittleCoins"
            :key="item.id"
            :value="item.id"
          >{{ item.coinKindItemName }}</a-select-option>
        </a-select>
        <!-- 分类 -->
        <a-select
          style="width: 150px; margin: 5px"
          size="small"
          showSearch
          v-model="searchParams.categoryId"
          :filter-option="untils.filterOption"
          placeholder="类别"
          allowClear
        >
          <a-select-option
            v-for="item of searchCategorys"
            :key="item.id"
            :value="item.id"
          >{{ item.coinCategoryName }}</a-select-option>
        </a-select>
        <a-button
          @click="search"
          size="small"
          style="margin-left: 10px"
        >搜索</a-button>
      </div>
      <div>
        <a-button
          size="small"
          @click="openModal({})"
        >新增版别</a-button>
      </div>
    </div>
    <div
      class="table-content"
      style="padding-top: 20px"
    >
      <a-table
        @change="changePage"
        :pagination="pagination"
        :loading="loading"
        :columns="columns"
        :data-source="data"
        :rowKey="(record) => record.id"
      >
      <!--
        <div
          slot="image"
          slot-scope="row"
        >
          <img
            @click="previewImg(row)"
            style="max-width: 50px; max-height: 50px"
            :src="row.coinKindVersionImage"
          />
        </div>
        -->
<!--    拓片图    -->
        <div slot="taPianImage" slot-scope="row">
          <img
              v-if="row.coinKindVersionImage"
              style="max-width:50px;max-height:50px;"
              :src="row.coinKindVersionImage"
              @click="handleShowBigImg([row.coinKindVersionImage, row.coinKindVersionBackImage], 0)"
          >
          <img
              v-if="row.coinKindVersionBackImage"
              style="max-width:50px;max-height:50px;"
              :src="row.coinKindVersionBackImage"
              @click="handleShowBigImg([row.coinKindVersionImage, row.coinKindVersionBackImage], 1)"
          >
        </div>
<!--    实物图    -->
        <div slot="shiWuImage" slot-scope="row">
          <img
              v-if="row.front_img"
              style="max-width:50px;max-height:50px;"
              :src="row.front_img"
              @click="handleShowBigImg([row.front_img, row.contrary_img], 0)"
          >
          <img
              v-if="row.contrary_img"
              style="max-width:50px;max-height:50px;"
              :src="row.contrary_img"
              @click="handleShowBigImg([row.front_img, row.contrary_img], 1)"
          >
        </div>
        <!-- 尺寸重量 -->
        <div
          class="size-weight"
          slot="sizeWeight"
          slot-scope="row"
        >
          {{`${row.versionSize || ""}/${row.versionWeight || ""}`}}
        </div>
        <div
          slot="Rate"
          slot-scope="row"
        >
          <span
            v-for="(item, index) of row.scoreList"
            :key="index"
          >{{ item.scoreName
            }}{{ index == row.scoreList.length - 1 ? "" : "," }}</span>
        </div>

        <div
          slot="coinKindVersionName"
          slot-scope="row"
        >
          <div>{{row.coinKindVersionName}}</div>  
          <div>{{row.isDuiban == 1?"(是)":"(否)"}}</div>
          <div>{{row.forbidRotate == 1?"(不可旋)":"(可旋)"}}</div>
          
        </div>

        <div
          slot="tags"
          slot-scope="row"
        >
          <a-tag
            v-for="(tag, key) in row.tagList"
            :key="key"
            color="blue"
          >{{
            tag.coinTagName
          }}</a-tag>
          <a-button
            @click="addTagClick(row)"
            size="small"
          >添加</a-button>
        </div>
<!--    hq版别-start    -->
        <div
            slot="hqSlot"
            slot-scope="row"
        >
          <div v-if="row.quanhaiVersionId">
            <div>{{ row.quanhaiVersionName }}</div>
            <div  style="margin-top: 10px;">
              <a-button
                  @click="handleChangeBinding(row, 'change')"
                  type="primary"
                  size="small"
              >修改</a-button>
              <a-popconfirm
                  style="margin-top: 10px;"
                  title="确定解绑吗？"
                  ok-text="Yes"
                  cancel-text="No"
                  @confirm="handleChangeBinding(row, 'none')"
              >
                <a-button
                    type="danger"
                    size="small"
                >解绑</a-button>
              </a-popconfirm>
            </div>
          </div>
          <div v-else>
            <a-button
                type="primary"
                @click="handleChangeBinding(row, 'change')"
                size="small"
            >绑定</a-button>
          </div>
        </div>
<!--    hq版别-end    -->
        <div
          slot="setup"
          slot-scope="row"
        >
          <a-popconfirm
            v-if="row.isSilver == 1"
            title="Are you sure clear this cache?"
            ok-text="Yes"
            cancel-text="No"
            @confirm="clearCache(row.id)"
          >
            <a style="margin-right: 5px">清缓存</a>
          </a-popconfirm>
          <a
            @click="openModal(row)"
            style="margin-right: 5px"
          >编辑</a>
          <a-popconfirm
            title="Are you sure delete this item?"
            ok-text="Yes"
            cancel-text="No"
            @confirm="remove(row.id)"
          >
            <a style="margin-left: 5px">删除</a>
          </a-popconfirm>
        </div>
        
      </a-table>
    </div>

    <a-modal
      v-model="visible"
      :title="innerTitle"
      ok-text="确认"
      cancel-text="取消"
      @cancel="resetForms"
      @ok="hideModal"
      :width="700"
    >
      <a-form-model
        ref="ruleForm"
        :model="forms"
        :rules="rules"
        v-bind="layout"
      >
        <a-form-model-item
          label="大币种"
          prop="coinKindSid"
        >
          <a-select
            @select="selectFn"
            @change="changeSelect"
            @search="searchData"
            ref="addse"
            v-model="forms.coinKindSid"
            :filter-option="untils.filterOption"
            showSearch
            class="w-300"
            placeholder="请选择"
          >
            <a-select-option
              v-for="item of currency_big_list"
              :title="item.coinCategoryId"
              :key="item.sid"
              :value="item.sid"
            >{{ computedShowBigCoinSelectedList(item) }}</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item
          label="小币种"
          prop="coinKindItemSid"
        >
          <a-select
            :filter-option="untils.filterOption"
            showSearch
            v-model="forms.coinKindItemSid"
            @change="changeSelectSmallCoin($event)"
            placeholder="请选择"
          >
            <a-select-option
              v-for="item of currency_small_list"
              :key="item.sid"
              :value="item.sid"
            >{{ item.coinKindItemName }}</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item
          label="版别名称"
          prop="coinKindVersionName"
        >
          <a-input
            placeholder="版别名称"
            v-model="forms.coinKindVersionName"
          />
        </a-form-model-item>

        <a-form-model-item label="版别特征" prop="versionTag" v-if="editCoinId">
          <a-select
              :dropdownMatchSelectWidth="false"
              :filter-option="untils.filterOption"
              :dropdownMenuStyle="{'max-height': '350px'}"
              allowClear
              showSearch
              mode="multiple"
              class="w-300 ml-10"
              v-model="versionNatureList"
              placeholder="选择版别特征"
          >
            <a-select-option
                v-for="item in versionNatureListData"
                :key="item.natureName"
                :value="item.natureName"
            >{{ item.natureName }}</a-select-option>
          </a-select>
          <a-button v-if="editCoinId" class="ml-20" type="primary" @click="handleAddVersionNatureType" >新增特征</a-button>
        </a-form-model-item>

        <a-form-model-item label="版别虚类" prop="versionClassify" v-if="editCoinId && editCoinItemId">
          <a-select
              :dropdownMatchSelectWidth="false"
              :filter-option="untils.filterOption"
              :dropdownMenuStyle="{'max-height': '350px'}"
              allowClear
              showSearch
              class="w-300 ml-10"
              v-model="forms.versionClassfyId"
              placeholder="选择版别虚类"
          >
            <a-select-option
                v-for="item in versionClassifyList"
                :key="item.id"
                :value="item.id"
            >{{ item.classfyName }}</a-select-option>
          </a-select>
          <a-button v-if="editCoinId" class="ml-20" type="primary" @click="handleGoToClassifyList" >去新增虚类</a-button>
        </a-form-model-item>

        <a-form-model-item label="价格区间">
          <a-input-group compact style="width: 300px;">
            <a-input v-model="forms.versionMinPrice" type="number" style=" width: 100px; text-align: center;" placeholder="最低价格" />
            <a-input style=" width: 30px; border-left: 0; pointer-events: none; backgroundColor: #fff" placeholder="~" disabled/>
            <a-input v-model="forms.versionMaxPrice" type="number" style="width: 100px; text-align: center; border-left: 0" placeholder="最高价格" />
            <a-button v-if="forms.versionMinPrice || forms.versionMaxPrice" @click="handleClearPriceRate" icon="close-circle"></a-button>
          </a-input-group>
        </a-form-model-item>
        <a-form-model-item
          label="版别描述"
          prop="versionDesc"
        >
          <a-input
            placeholder="版别描述"
            type="textarea"
            :rows="3"
            v-model="forms.versionDesc"
          />
        </a-form-model-item>
        <a-form-model-item
          label="内部标记"
          prop="versionDesc"
        >
          <a-input
            placeholder="内部标记"
            type="textarea"
            :rows="3"
            v-model="forms.internalMark"
          />
        </a-form-model-item>
        <a-form-model-item label="标签">
          <a-tag
            closable
            @close="removeTag(forms.tagList, _index)"
            v-for="(_item, _index) in forms.tagList"
            :key="_index"
            @click="confirmTag(_item, _index)"
          >{{ _item.coinTagName }}</a-tag>
          <a-button
            @click="confirmTag({})"
            size="small"
          >添加</a-button>
        </a-form-model-item>

        <a-form-model-item v-if="showSet == true" label="PCGS编码" prop="pcgsCode">
          <a-input
            placeholder="PCGS编码"
            v-model="forms.pcgsCode"
          />
        </a-form-model-item>
        <a-form-model-item label="评分" v-if="showSet == true">
          <a-checkable-tag
            v-for="(item, index) of editRanks"
            :key="index"
            :checked="item.checked"
            @change="changeTag(index)"
          >
            {{ item.scoreName }}
          </a-checkable-tag>
        </a-form-model-item>

        <a-form-model-item label="排序">
          <a-input
            placeholder="排序"
            v-model="forms.coinKindVersionSort"
          />
        </a-form-model-item>

        <a-form-model-item label="是否推荐">
          <a-select
            @change="changeFormsRe"
            allowClear
            size="small"
            v-model="forms.coinKindVersionRecommend"
            placeholder="数据源"
          >
            <a-select-option :value="0">不推荐</a-select-option>
            <a-select-option :value="1">推荐</a-select-option>
          </a-select>
        </a-form-model-item>

        <a-form-model-item
          v-if="forms.coinKindVersionRecommend == 1"
          label="推荐排序"
        >
          <a-input
            placeholder="推荐排序"
            v-model="forms.recommendSort"
          />
        </a-form-model-item>
        <a-form-model-item label="拓片图 正面url">
          <a-input
            placeholder="拓片图 正面url"
            v-model="forms.coinKindVersionImage"
          />
        </a-form-model-item>
        <a-form-model-item label="拓片图 反面url">
          <a-input
            placeholder="拓片图 反面url"
            v-model="forms.coinKindVersionBackImage"
          />
        </a-form-model-item>
        <!-- v-if="showSet == true"  -->
        <a-form-model-item label="拓片 正面图">
          <UploadImage
            :list="VImages"
            :maxCount="1"
            @change="VImagesChange"
          ></UploadImage>
        </a-form-model-item>
        <a-form-model-item label="拓片 反面图">
          <UploadImage
            :list="VBImages"
            :maxCount="1"
            @change="VBImagesChange"
          ></UploadImage>
        </a-form-model-item>
        <a-form-item label="实物正反图片">
          <UploadImage
            :list="coverList"
            :maxCount="2"
            @change="coverListChange"
          ></UploadImage>
        </a-form-item>

        <a-form-model-item label="版别级别">
          <a-select
            allowClear
            size="small"
            v-model="forms.versionGrade"
            placeholder="版别级别"
          >
            <a-select-option value="无">无</a-select-option>
            <a-select-option value="一级">一级</a-select-option>
            <a-select-option value="二级">二级</a-select-option>
            <a-select-option value="三级">三级</a-select-option>
            <a-select-option value="四级">四级</a-select-option>
            <a-select-option value="五级">五级</a-select-option>
            <a-select-option value="六级">六级</a-select-option>
            <a-select-option value="七级">七级</a-select-option>
            <a-select-option value="八级">八级</a-select-option>
            <a-select-option value="九级">九级</a-select-option>
            <a-select-option value="十级">十级</a-select-option>
            <a-select-option value="常见版">常见版</a-select-option>
            <a-select-option value="稀有版">稀有版</a-select-option>
            <a-select-option value="名誉版">名誉版</a-select-option>
            <a-select-option value="多见">多见</a-select-option>
            <a-select-option value="较多">较多</a-select-option>
            <a-select-option value="略少">略少</a-select-option>
            <a-select-option value="珍稀">珍稀</a-select-option>
            <a-select-option value="罕见">罕见</a-select-option>
            <a-select-option value="少见">少见</a-select-option>
            <!-- <a-select-option
              v-for="(n,index) in 10"
              :value="index"
              :key="index"
            >{{index === 0 ? '无' : `${index}级`}}</a-select-option> -->
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="版别尺寸">
          <a-input
            placeholder="版别尺寸"
            v-model="forms.versionSize"
          />
        </a-form-model-item>
        <a-form-model-item label="版别重量">
          <a-input
            placeholder="版别重量"
            v-model="forms.versionWeight"
          />
        </a-form-model-item>

        <a-form-model-item label="尺寸重量描述">
          <a-textarea
              type="text"
              style="width: 600px;"
              v-model="forms.sizeWeightRemark"
              :auto-size="{ minRows: 3, maxRows: 6 }"
              placeholder="尺寸重量描述"
          ></a-textarea>
          <div class="color-red">tip：展示于图库详情页，全文案描述</div>
        </a-form-model-item>

        <a-form-model-item label="泉谱书籍">
          <a-input
            placeholder="泉谱书籍"
            v-model="forms.quanpuBook"
          />
        </a-form-model-item>

        <a-form-model-item label="书籍编号">
          <a-input
            placeholder="书籍编号"
            v-model="forms.quanpuBookSerial"
          />
        </a-form-model-item>

        <a-form-model-item label="是否对版">
          <a-radio-group
            name="radioGroup"
            v-model="forms.isDuiban"
          >
            <a-radio :value="1">是</a-radio>
            <a-radio :value="0">否</a-radio>
          </a-radio-group>

        </a-form-model-item>

        <a-form-model-item label="是否旋转">
          <a-radio-group
            name="radioGroup"
            v-model="forms.forbidRotate"
          >
            <a-radio :value="0">是</a-radio>
            <a-radio :value="1">否</a-radio>
          </a-radio-group>

        </a-form-model-item>

        <a-form-model-item label="图库禁用该版">
          <a-radio-group
              name="radioTuku"
              v-model="forms.forbidTuku"
          >
            <a-radio :value="0">否</a-radio>
            <a-radio :value="1">是</a-radio>
          </a-radio-group>
        </a-form-model-item>

        <a-form-model-item label="成交价搜索类型：">
          <a-select
            allowClear
            v-model="forms.goodpriceSearchType"
            placeholder="成交价搜索类型"
          >
            <a-select-option :value="0">大币种 AND 版别名称 + 版别ID</a-select-option>
            <a-select-option :value="5">大币种 AND 搜索词 + 版别ID</a-select-option>
            <a-select-option :value="10">版别ID精确匹配</a-select-option>
          </a-select>
        </a-form-model-item>

        <div v-if="forms.goodpriceSearchType === 5">
          <a-form-model-item
              v-for="(item, index) in customSearchWordList"
              :key="item.value"
              :label="`成交价搜索自定义名称--${index + 1}：`"
          >
            <a-input
                class="w-300"
                placeholder="成交价搜索自定义名称"
                v-model="customSearchWordList[index].text"
            />
            <a-button
                v-if="customSearchWordList.length - 1 === index"
                @click="handleAddCustomSearchWordItem(index)"
                class="ml-20"
                type="primary"
                icon="plus"></a-button>
            <a-button
                v-if="customSearchWordList.length > 1"
                @click="handleDeleteCustomSearchWordItem(index)"
                class="ml-20"
                type="danger"
                icon="minus"></a-button>
          </a-form-model-item>
        </div>
      </a-form-model>
    </a-modal>

<!--  标签  -->
    <a-modal
      v-model="tagVisible"
      title="标签"
      ok-text="确认"
      cancel-text="取消"
      @cancel="tagForms = {}"
      @ok="addTag"
      :width="534"
    >
      <a-form-model
        ref="tagforms"
        :model="tagForms"
        :rules="tagRules"
        v-bind="layout"
      >
        <a-form-model-item
          label="名称"
          prop="coinTagName"
        >
          <a-input
            placeholder="名称"
            v-model="tagForms.coinTagName"
          />
        </a-form-model-item>

        <a-form-model-item
          label="标签分类"
          prop="coinTagCategory"
        >
          <a-select
            placeholder="标签分类"
            v-model="tagForms.coinTagCategory"
          >
            <a-select-option :value="1">别名标签</a-select-option>
            <a-select-option :value="2">钱币性质标签</a-select-option>
          </a-select>
        </a-form-model-item>
      </a-form-model>
    </a-modal>

<!--  评分  -->
    <a-modal
      v-model="rateVisible"
      title="评分"
      ok-text="确认"
      cancel-text="取消"
      @ok="addRate"
      @cancel="rateCancel"
      :width="534"
    >
      <a-input v-model="rateInner"></a-input>
    </a-modal>

    <BottleAddTag
      ref="addTag"
      @success="addTagSuccess"
    ></BottleAddTag>
<!-- 绑定版别   -->
    <BindingVersionPopup
        ref="bind-version"
        @success="getData"
    />
<!--  新增版别特征弹窗  -->
    <AddVersionNatureTypePopup ref="addVersionNatureTypePopupEl" @change="getVersionNatureListEvent"/>
  </div>
</template>

<script>
import { mapState } from "vuex";
import BottleAddTag from "@/views/cmsPage/currencyMannage/_components/bottleAddTag.vue";
import UploadImage from "@/components/UploadImage/index.vue";
import BindingVersionPopup from "@/views/cmsPage/currencyMannage/_components/bindingVersionPopup";
import untils from "@/untils"
import AddVersionNatureTypePopup from "@/views/cmsPage/currencyMannage/_components/AddVersionNatureTypePopup/index.vue"
import {getVersionNatureListApi} from "@/views/cmsPage/currencyMannage/_apis"
import {getVersionClassifyListApi} from "@/views/cmsPage/currencyMannage/versionClassifyManage/_apis"
import {goToOtherPage} from "@/untils/otherEvent"
import _bigCoinShowSelectList from "@/_mixins/_bigCoinShowSelectList"
import {getBigCoinList} from "@/axios/apis"

export default {
  mixins: [_bigCoinShowSelectList],
  data() {
    return {
      versionClassifyList: [], // 版别类别列表
      editCoinId: 0,
      editCoinItemId: 0,
      versionNatureList: [], // 选择的版别特征列表
      versionNatureListData: [], // 版别特征列表
      // 自定义搜索词列表
      customSearchWordList: [
          { text: undefined, value: new Date().getTime() },
      ],
      untils,
      columns: [
        { title: "ID", dataIndex: "id" },
        { title: "版别名称(是否对版-旋转)", scopedSlots: { customRender: "coinKindVersionName" } },
        // { title: "版别名称", dataIndex: "coinKindVersionName" },
        {title: 'hq版别', scopedSlots: { customRender: "hqSlot" }, width: 150},
        { title: "内部标记", dataIndex: "internalMark", width: 150 },
        { title: "版别描述", dataIndex: "versionDesc", width: 150 },
        { title: "拓片图", scopedSlots: { customRender: "taPianImage" }, width: 200, align: 'center' },
        { title: "实物图", scopedSlots: { customRender: "shiWuImage" }, width: 200, align: 'center' },
        {
          title: "尺寸/重量",
          scopedSlots: { customRender: "sizeWeight" },
          width: 150,
        },
        { title: "大币种", dataIndex: "kindName" },
        { title: "小币种", dataIndex: "kindItemName" },
        { title: "版别特征", dataIndex: "natureValue", width: 200 },
        { title: "版别虚类归类", dataIndex: "versionClassfyName", width: 100 },
        { title: "分类", dataIndex: "categoryName" },
        { title: "标签", scopedSlots: { customRender: "tags" } },
        { title: "级别", dataIndex: "versionGrade",},
        {
          title: "操作",
          align: "center",
          scopedSlots: { customRender: "setup" },
        },
      ],
      data: [],
      layout: {
        labelCol: { span: 5 }, //标签占5份  共24份
        wrapperCol: { span: 19 },
      },
      imgKey: "",
      coverList: [],
      forms: {
        forbidTuku: 0,
        versionClassfyId: undefined,
      },
      tagForms: {},
      rules: {
        coinKindSid: [
          { required: true, message: "please change", trigger: "change" },
        ],
        coinKindVersionName: [
          {
            required: true,
            message: "please enter coinKindVersionName",
            trigger: "blur",
          },
        ],
        coinKindItemSid: [
          {
            required: true,
            message: "please enter coinKindItemName",
            trigger: "blur",
          },
        ],
      },
      tagRules: {
        coinTagCategory: [
          { required: true, message: "please change", trigger: "change" },
        ],
        coinTagName: [
          { required: true, message: "please change", trigger: "change" },
        ],
      },
      currency_small_list: [],
      currency_big_list: [],
      visible: false,
      tagVisible: false,
      innerTitle: "",
      currency_type_list: [],
      IO: 0,
      tagCurrent: "",
      pagination: {
        total: 0,
        current: 1,
        showTotal: (total) => `共${total}条`,
      },
      loading: false,
      searchParams: {
        pageNum: 1,
      },
      showSet: false,
      rateVisible: false,
      rateInner: "",
      ranks: [
        "30",
        "35",
        "40",
        "45",
        "50",
        "53",
        "55",
        "58",
        "60",
        "61",
        "62",
        "63",
        "64",
        "65",
        "66",
        "67",
        "VF",
        "XF",
        "AU",
        "UNC",
        "SP 58",
        "SP 60",
        "SP 61",
        "SP 62",
        "SP 63",
        "SP 64",
        "SP 65",
        "SP 66",
      ],
      editRanks: [],
      searchBigCoins: [], //大币种搜索结果
      searchLittleCoins: [], //小币种结果
      searchCategorys: [], //类别
      curBigCoin: "", //当前搜索的大币种
      curBottle: "",
      VImages: [], //版别正面图
      VBImages: [], //版别fan面图
    };
  },
  computed: {
    ...mapState(["ip"]),
  },
  watch: {
    visible() {
      this.imgKey = this.visible ? "" : Math.random();
    },
  },
  components: {
    BottleAddTag, UploadImage, BindingVersionPopup, AddVersionNatureTypePopup
  },
  created() {
    this.getData();
    this.getSearchCategorys();
    this.initRanks();
  },
  methods: {
    /** 去往新增类别 */
    handleGoToClassifyList() {
      goToOtherPage('/cms/currencymannage/versionClassfy')
    },
    /** 获取版别归类类别名称 */
    async getVersionClassifyListEvent() {
      const tempParams = {
        coinId: this.editCoinId,
        coinItemId: this.editCoinItemId,
        pageSize: 100,
        pageNum: 1
      }
      const res = await getVersionClassifyListApi(tempParams)
      if (res.status !== '200') return
      this.versionClassifyList = res.data.records
    },
    /** 新增版别特征 */
    handleAddVersionNatureType() {
      this.$refs.addVersionNatureTypePopupEl.show(this.editCoinId)
    },
    /** 新增版别特征成功 */
    async getVersionNatureListEvent() {
      const res = await getVersionNatureListApi(this.editCoinId)
      if (res.status !== '200') return
      this.versionNatureListData = res.data.records
    },
    /** 清空价格区间 */
    handleClearPriceRate() {
      this.forms.versionMinPrice = undefined
      this.forms.versionMaxPrice = undefined
    },
    /** 删除自定义词 */
    handleDeleteCustomSearchWordItem(index) {
      this.customSearchWordList.splice(index, 1)
    },
    /** 追加自定义词 */
    handleAddCustomSearchWordItem(index) {
      this.customSearchWordList.push({
        text: undefined,
        value: new Date().getTime()
      })
    },
    /** 放大图 */
    handleShowBigImg(urls, index) {
      if (urls && urls.length > 0) {
        this.$previewImg({
          list: urls.reduce((pre, cur) => {
            const obj = { img_url: cur };
            pre = pre.concat(obj);
            return pre;
          }, []),
          baseImgField: "img_url",
          showMute: false,
          current: index
        });
      }
    },
    /** 修改绑定，或者解绑 */
    async handleChangeBinding(item, type) {
      if (type === 'none') {
        const res = await this.axios.post("/dq_admin/qh/ownerVersionBindQuanhaiVersionId", {
          ownerVersionId: item.id,
          quanhaiVersionId: item.quanhaiVersionId,
          bindType: 1
        })
        if (res.status != '200') return
        this.$message.success(`${res.message || '操作成功'}`)
        await this.getData()
      } else {
        await this.$refs["bind-version"].show(item, item.coinKindVersionName)
      }
    },
    // 预览封面
    previewImg(row) {
      const images = [];
      const { coinKindVersionImage, coinKindVersionBackImage } = row;
      if (coinKindVersionImage) {
        images.push({
          img_url: coinKindVersionImage,
        });
      }
      if (coinKindVersionBackImage) {
        images.push({
          img_url: coinKindVersionBackImage,
        });
      }
      this.$previewImg({
        list: images,
        baseImgField: "img_url",
        showMute: false,
      });
    },
    // 新增标签成功
    async addTagSuccess(form) {
      const { coinTagName, coinTagCategory } = form;
      if (!coinTagName) return;
      const res = await this.axios.post("/dq_admin/tag/add", {
        coinBaseSid: this.curBottle.sid,
        coinBaseSidType: 3,
        coinTagName,
        coinTagCategory,
      });
      if (res.status != 200) return;
      this.$refs["addTag"].close();
      this.getData();
    },
    // 列表新增标签
    addTagClick(row) {
      this.curBottle = JSON.parse(JSON.stringify(row));
      this.$refs["addTag"].open();
    },
    // 分类列表
    async getSearchCategorys() {
      const res = await this.axios("/dq_admin/category/list");
      this.searchCategorys = res.data.records;
    },
    // 搜索，大币种搜索
    async bigCoinSearch(str) {
      const res = await getBigCoinList(str)
      if (res.status !== '200') return;
      const { records } = res.data;
      this.searchBigCoins = records;
    },
    // 搜索结果选择大币种
    selectBigCoin(sid) {
      if (sid == undefined) {
        delete this.searchParams.coinId;
        delete this.searchParams.coinItemId;
        this.curBigCoin = "";
        return;
      }
      const bigCoin = this.searchBigCoins.find((el) => {
        return sid == el.sid;
      });
      this.curBigCoin = JSON.parse(JSON.stringify(bigCoin));
      this.searchParams.coinId = bigCoin.id;
      this.searchLittleCoin();
    },
    // 搜索小币种
    async searchLittleCoin() {
      const res = await this.axios("/dq_admin/kind/getItemAndVersion", {
        params: { sid: this.searchParams.coinSId },
      });
      const { coinItem, coinItemVersion } = res.data;
      this.searchLittleCoins = coinItem;
    },
    /** 初始化设置自定义搜索词 */
    setCustomSearchWordList(data) {
      if (data.includes(',')) {
        const tempDataList = data.split(',');
        this.customSearchWordList = new Array(tempDataList.length).fill({ text: undefined, value: new Date().getTime()});
        tempDataList.forEach((el, index) => {
          this.customSearchWordList[index] = { text: el, value: index}
        })
      } else if (data) {
        this.customSearchWordList[0] = { text: data, value: new Date().getTime() }
      }
    },
    async openModal(row) {
      const { id } = row;
      const type = id ? 1 : 0;
      this.IO = type;
      this.visible = true;
      const text = type == 0 ? "新增版别" : "编辑版别";
      this.initRanks(type == 0 ? "add" : "edit");
      this.innerTitle = text;
      if (row.id) {
        await this.searchData(row.kindName);
        await this.changeSelect(row.coinKindSid);
        console.log(this.currency_small_list, row.coinKindItemSid, '获取小币种')
        await this.changeSelectSmallCoin(row.coinKindItemSid)
        this.versionNatureList = row.natureValue ? row.natureValue.split(',') : []
      }
      this.forms = id ? JSON.parse(JSON.stringify(row)) : { tagList: [] };
      if (this.forms.versionClassfyId <= 0) this.$set(this.forms, "versionClassfyId", undefined);
      if (!this.forms.scoreList) {
        this.forms.scoreList = [];
      }
      if (row.goodpriceSearchAlias) {
        this.setCustomSearchWordList(row.goodpriceSearchAlias)
      }
      const {
        coinKindItemId,
        coinKindId,
        coinKindSid,
        kindName,
        coinKindItemSid,
        kindItemName,
        scoreList,
        coinKindVersionImage,
        coinKindVersionBackImage,
        coinKindVersionRealImage,
        coinKindVersionRealBackImage,
      } = this.forms;
      // 版别正面图
      if (coinKindVersionImage) {
        this.pushImage("VImages", coinKindVersionImage, 1);
      }
      // 版别反面图
      if (coinKindVersionBackImage) {
        this.pushImage("VBImages", coinKindVersionBackImage, 1);
      }
      // 原始正面图
      if (coinKindVersionRealImage) {
        this.pushImage("coverList", coinKindVersionRealImage, 1);
      }
      // 原始反面图
      if (coinKindVersionRealBackImage) {
        this.pushImage("coverList", coinKindVersionRealBackImage, 2);
      }

      const list = scoreList.map((row) => {
        return row.scoreName;
      });
      this.editRanks.map((row) => {
        list.map((element) => {
          if (row.scoreName == element) {
            row.checked = true;
          } else {
            row.checked = false;
          }
        });
      });

      if (this.forms.isSilver) {
        this.showSet = true;
      }
    },
    // 回显图片
    pushImage(key, url, index) {
      this[key].push({
        uid: index,
        name: "image",
        status: "done",
        url: url,
        response: { data: url },
      });
    },
    hideModal() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          this.submit(this.forms);
        } else {
          return false;
        }
      });
    },
    resetForms() {
      this.versionNatureList = []
      this.forms = {};
      this.$refs.ruleForm.resetFields();
      this.currency_big_list = [];
      this.VImages = [];
      this.VBImages = [];
      this.coverList = [];
    },
    /** 计算自定义搜索词 */
    computedCustomSearchWordList() {
      const tempDataList = []
      this.customSearchWordList.forEach(el => {
        if (el.text) {
          tempDataList.push(el.text)
        }
      })
      return tempDataList.length ? tempDataList.join(',') : '';
    },
    submit(forms) {
      forms.natureValue = this.versionNatureList.join(',')
      const params = JSON.parse(JSON.stringify(forms));
      params.goodpriceSearchAlias = this.computedCustomSearchWordList()
      const list = this.editRanks.reduce((pre, cur) => {
        if (!pre) {
          pre = [];
        }
        const { checked } = cur;
        if (checked) {
          pre.push(cur);
        }
        return pre;
      }, []);
      if (this.showSet == true) {
        params.scoreList = list;
      } else {
        params.scoreList = [];
      }
      // 正面图
      if (!params.coinKindVersionImage && this.VImages[0]) {
        params.coinKindVersionImage = this.VImages[0].response.data;
      }
      // 反面图
      if (!params.coinKindVersionBackImage && this.VBImages[0]) {
        params.coinKindVersionBackImage = this.VBImages[0].response.data;
      }
      // 原始正面图
      if (this.coverList[0]) {
        params.coinKindVersionRealImage = this.coverList[0].response.data;
      } else {
        params.coinKindVersionRealImage = ''
      }
      // 原始反面图
      if (this.coverList[1]) {
        params.coinKindVersionRealBackImage = this.coverList[1].response.data;
      } else {
        params.coinKindVersionRealBackImage = ''
      }

      if (!this.forms.versionClassfyId) {
        params.versionClassfyId = ''
        params.versionClassfyName = ''
      }

      const post_url =
        this.IO == 1
          ? "/dq_admin/kinditemversion/edit"
          : "/dq_admin/kinditemversion/add";
      this.axios.post(post_url, params).then((res) => {
        if (res.status == "SUCCESS") {
          this.$message.success(res.message);
          this.visible = false;
          this.getData();
          this.VImages = [];
          this.VBImages = [];
          this.coverList = [];
          this.customSearchWordList = [
            { text: undefined, value: 0 },
          ]
        }
      });
    },
    async getData() {
      this.loading = true;
      const params = JSON.parse(JSON.stringify(this.searchParams));
      delete params["coinSId"];
      const res = await this.axios("/dq_admin/kinditemversion/list2", {
        params: params,
      });
      this.loading = false;
      const { records, total } = res.data;
      this.data = records;
      this.pagination.total = total;
    },
    search() {
      this.$set(this.searchParams, "pageNum", 1);
      this.pagination.current = 1;
      this.getData();
    },
    async remove(id) {
      const res = await this.axios(
        "/dq_admin/kinditemversion/delKindItemVersionById",
        { params: { id: id } }
      );
      if (res.status == "200") {
        this.$message.success(res.message);
        this.getData();
      }
    },
    async clearCache(id) {
      const res = await this.axios(
        "/dq_admin/kinditemversion/clearcacheItemVersionById",
        { params: { id: id } }
      );
      if (res.status == 200) {
        this.$message.success(res.message);
        this.getData();
      }
    },
    addTag() {
      this.$refs.tagforms.validate((valid) => {
        if (valid) {
          if (typeof this.tagCurrent == "number") {
            this.forms.tagList.splice(this.tagCurrent, 1, this.tagForms);
          } else {
            this.forms.tagList.push(this.tagForms);
          }
          this.tagVisible = false;
        } else {
          return false;
        }
      });
    },
    confirmTag(row, index) {
      const { coinTagCategory } = row;
      this.tagVisible = true;
      this.tagForms = row;
      this.$set(this.tagForms, "coinTagCategory", coinTagCategory || 1);
      this.tagCurrent = index || index === 0 ? index : "";
    },
    removeTag(s, n) {
      s.splice(n, 1);
    },
    changePage(page) {
      const { current, pageSize } = page;
      this.pagination.current = current;
      this.searchParams.pageNum = current;
      this.getData();
    },
    /** 切换小币种 */
    async changeSelectSmallCoin(sid) {
      if (this.currency_small_list.length) {
        const found = this.currency_small_list.find((el) => sid === el.sid)
        if (found) {
          this.editCoinItemId = found.id
        }
        await this.getVersionClassifyListEvent()
      }
    },
    /** 获取小币种 */
    async getSmallCoinListEvent(sid) {
      const res = await this.axios("/dq_admin/kind/getItemAndVersion", {params: { sid: sid },})
      if (res.status !== 'SUCCESS') return
      const { coinItem } = res.data;
      this.currency_small_list = coinItem || []
      console.log(this.currency_small_list, '获取小币种---API')
    },
    /** 切换大币种 */
    async changeSelect(sid) {
      // currency_big_list
      if (this.currency_big_list.length) {
        this.editCoinId = this.currency_big_list.find((el) => sid === el.sid).id
        await this.getVersionNatureListEvent()
        await this.getSmallCoinListEvent(sid)
     }
    },
    async searchData(inner) {
      const res = await getBigCoinList(inner)
      if (res.status !== '200') return;
      const { records } = res.data;
      this.currency_big_list = records;
    },
    async handlePreview(list) {
      let urls = list.map((el) => {
        return {
          img_url: el.url,
        };
      });
      this.$previewImg({
        list: urls,
        baseImgField: "img_url",
        showMute: false,
      });
    },
    VImagesChange(list) {
      this.VImages = list;
      if (list[0] && list[0].response) {
        this.$set(this.forms, "coinKindVersionImage", list[0].response.data)
      }
    },
    VBImagesChange(list) {
      this.VBImages = list;
      if (list[0] && list[0].response) {
        this.$set(this.forms, "coinKindVersionBackImage", list[0].response.data)
      }
    },
    coverListChange(list) {
      this.coverList = list;
    },
    selectFn(e, s) {
      if (s.componentOptions.propsData.title == 2) {
        this.showSet = true;
      } else {
        this.showSet = false;
      }
    },
    openRateModal() {
      this.rateVisible = true;
    },
    addRate() {
      this.forms.scoreList.push({
        scoreName: this.rateInner,
      });
      this.rateVisible = false;
      this.rateInner = "";
    },
    rateCancel() {
      this.rateInner = "";
    },
    changeTag(s) {
      this.editRanks[s].checked = !this.editRanks[s].checked;
    },
    initRanks(type) {
      const list = this.ranks.map((row) => {
        return {
          scoreName: row,
          checked:
            type == "edit"
              ? false
              : row == "66" || row == "67" || row == "70" || row == "SP"
              ? false
              : true,
        };
      });
      this.editRanks = list;
    },
    changeFormsRe(e) {},
  },
};
</script>

<style lang="scss" scoped>
.search-attr {
  display: flex;
  justify-content: space-between;
}
.content {
  display: flex;
  flex-direction: column;
  .table-content {
    flex: 1;
    overflow-y: auto;
  }
}
</style>