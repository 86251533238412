<template>
  <div>
    <a-modal
        :width="600"
        v-model="show"
        title="送评流程"
        @ok="show = !show"
    >
      <div class="rate-step-content">
        <a-steps progress-dot :current="0" direction="vertical" v-if="popupData && popupData.length">
          <a-step
              :class="[10, 30].includes(item.qualityType) ? 'cur-pot' : ''"
              v-for="(item, index) in popupData"
              :key="index"
              :title="item.createTime"
              :description="computedDesc(item)"
              @click.prevent.stop="handleGoToList(item)"
          />
        </a-steps>
        <div v-else class="flex-center-center mt-20">暂无流程~</div>
      </div>
    </a-modal>
  </div>
</template>
<script>
import {getRateManageRateStepList} from "@/views/cmsPage/checkManage/_apis"
import {rateStatusQualityTypeMapText, rateStatusReviewStatusTypeMapText} from "@/views/cmsPage/checkManage/_data"

export default {
  data() {
    return {
      show: false,
      popupData: '',
      ratingId: '',
      rowData: ''
    };
  },
  computed: {
    computedDesc() {
      return (item) => {
        const qualityTypeText = rateStatusQualityTypeMapText(item.qualityType)
        const reviewStatusText = rateStatusReviewStatusTypeMapText(item.reviewStatus) || '暂无'
        const qualityTypeDesc = item.qualityType === 10 ? '（点击查看初检结果）' : item.qualityType === 30 ? '（点击查看最终结果）' : ''
        if ([15, 40].includes(item.qualityType)) {
          return `${qualityTypeText}； 操作人：${item.sysAdmin}`
        } else {
          return `${qualityTypeText}；${item.qualityType === 20 ? `` : `鉴定结果：${reviewStatusText}；`}  操作人：${item.sysAdmin}${qualityTypeDesc}`
        }
      }
    },
  },
  methods: {
    /** 去往鉴定列表 */
    handleGoToList(item) {
      if (item.qualityType === 10) {
        this.handleGoToOrderABCDByInit()
      } else if (item.qualityType === 30) {
        this.handleGoToOrderABCDByEnd()
      }
    },
    /** 去往初检---ABCD类 */
    handleGoToOrderABCDByInit() {
      const origin = location.origin
      let url = ''
      if (['A', 'B', 'C'].includes(this.rowData.orderRatingServiceRank)) {
        url = `${origin}/#/cms/dqquality/operate?orderNo=${this.rowData.orderNo}`
      } else {
        url = `${origin}/#/cms/dqquality/operate?personApplyNo=${this.rowData.personApplyNo}`
      }
      window.open(url)
    },
    /** 去往终极结果---ABCD类 */
    handleGoToOrderABCDByEnd() {
      const origin = location.origin
      let url = ''
      if (['A', 'B', 'C'].includes(this.rowData.orderRatingServiceRank)) {
        url = `${origin}/#/cms/identifyconfirm/resultabclist?orderNo=${this.rowData.orderNo}&reviewStatus=${1}`
      } else {
        url = `${origin}/#/cms/identifyconfirm/resultdlist?applyNo=${this.rowData.personApplyNo}&reviewStatus=${1}`
      }
      window.open(url)
    },
    async showPopup(id, row) {
      this.rowData = row
      this.ratingId = id
      await this.getRateStatusStep()
      this.show = true
    },
    /** 获取数据 */
    async getRateStatusStep() {
      this.$loading.show()
      const res = await getRateManageRateStepList({ ratingId: this.ratingId })
      this.$loading.hide()
      if (res.status !== '200') return
      this.popupData = res.data
    },
    /** 取消 */
    handleCancelPrint() {
      this.show = false
      this.popupData = ''
    }
  },
};
</script>

<style lang="scss" scoped>
.rate-step-content {
  width: 100%;
}
.rate-step-content::v-deep .ant-steps-item-content {
  width: 80% !important;
}
</style>
